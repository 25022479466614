@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://db.onlinewebfonts.com/c/e71d1c4ccde9bca057cd4d7be7a7ba40?family=Themysion");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Reem+Kufi:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Reem+Kufi:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Reem+Kufi:wght@400..700&display=swap");

* {
  margin: 0;
}

.about-me,
.terms,
.kay-glam {
  margin: 16px;
}

nav img {
  height: 70px;
}

.hero {
  font-style: italic;
}

.artist {
  font-size: 2rem;
  font-weight: 200;
  align-items: center;
}

.brush {
  font-size: 4rem;
  font-weight: 300;
  font-style: italic;
}

.artist,
.brush {
  text-align: center;
}

h3 {
  font-family: "Themysion", sans-serif;
  font-size: 100px;
  margin-left: 20px;
  font-weight: 100;
  margin-bottom: -40px;
  margin-top: 20px;
}
.page-title {
  color: #3e251e;
}

.sub-title {
  font-family: "Themysion", sans-serif;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 10px;
  font-style: bold;
  margin-top: 10px;
  margin-bottom: -20px;
}

.sub-title:last-child {
  margin-bottom: 0;
}

.more-sub-title {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  margin-left: 15px;
}

.tiny-text {
  color: #644f48;
}

.tiny-text,
li {
  font-family: "Reem Kufi", sans-serif;
  margin-bottom: 3px;
  margin-left: 10px;
  color: #3e251e;
}

.maids-discount {
  margin-left: 20px;
  font-family: "Reem Kufi", sans-serif;
  color: #3e251e;
}

.bottom-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}
.no-kids {
  font-family: "Reem Kufi", sans-serif;
  color: #3e251e;
}

.terms-cond {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Reem Kufi", sans-serif;
  font-weight: 300;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Themysion", sans-serif;
  color: #3e251e;
}

.fancy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Hero Text */
.heading-style {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-size: 3rem;
  padding-bottom: 18%;
  margin: 0;
}

/* NavBar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 60px;
  font-family: "Poppins", sans-serif;
}

.navlink {
  color: #644f48;
  font-weight: 400;
  margin: 0 10px;
  text-decoration: none;
}
.navlinks .navlink:hover {
  border-bottom: 2px solid #3e251e;
}

.burger {
  display: none;
}

.service-button {
  background: none;
  border: none;
  display: flex;
  font-size: 4rem;
  cursor: pointer;
  font-family: "Themysion", sans-serif;
  margin-left: 1rem;
  font-weight: 100;
  margin-bottom: -2rem;
  margin-top: 1.5rem;
}

.service-button:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .burger {
    display: block;
    margin-right: 5%;
  }

  .navlinks {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    background-color: transparent;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    z-index: 20;
    margin-right: 3%;
  }

  .navlinks.open {
    display: flex;
  }

  .navlinks .navlink:hover {
    font-size: large;
    border: 1px solid #3e251e;
    border-radius: 5px;
  }

  nav img {
    max-width: 40%;
    height: auto;
  }

  /* Hero Text  */

  .heading-style {
    font-size: 2rem;
    padding-bottom: 30%;
  }

  .image-container {
    max-height: 90%;
  }

  .terms-cond {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  h3 {
    font-size: 55px;
    margin-bottom: -20px;
  }

  .fancy img {
    width: 100%;
  }

  section {
    font-size: 0.7rem;
  }

  .packages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .service-button {
    font-size: 2.9rem;
    margin-left: 0.5rem;
  }

  .packages .maids-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sub-title {
    font-size: 3.3rem;
  }
}

@media (max-width: 369px) {
  .service-button {
    font-size: 1.98rem;
    margin-left: 0.1rem;
  }
}
/* mosaic */

.image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  max-height: 90%;
  margin-bottom: 1%;
  overflow-y: auto;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container img {
  grid-column: span 4;
  grid-row: span 2;
}

.packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
